.mainImage {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
}
.mainImage img {
    opacity: 0.7;
    height: 100%;
    vertical-align: top;
}

@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');
.mainImageText {
    font-family: 'DotGothic16', sans-serif;
    line-height: 40px;
    font-size: clamp(18px, 8vw, 60px);
    width: 100%;
    height: 200px;
    position: absolute;
    text-align: center;
    margin: 0;
    padding: 0;
}

.mainImageBottomText {
    position: absolute;
    text-align: center;
}

.animated {
    animation: move-y 0.5s infinite alternate ease-in-out;
    display: inline-block;
}
@keyframes move-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(10px);
    }
}


.mainContent {
    margin-top: calc(100vh - 80px);
    display: none;
}


@keyframes move-y-comingsoon {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(5px);
    }
}
.commingsoon {
    width: 100%;
    margin-top: 100px;
    animation: move-y-comingsoon 0.8s infinite alternate ease-in-out;
    display: inline-block;
}
.commingsoon div {
    width: min(70vw, 800px);
    height: 80px;
    margin: -80px auto;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgba(38, 125, 255, 0.459);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);

    color: white;
}
.comingsoonText * {
    font-size: clamp(16px, 8vw, 50px);
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    margin: 20px 0 0 0;
}