@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');


html * {
    cursor: url("/public/images/global/WoodenSwordCursor.png"), auto;
}

#headerSnsButtons {
    cursor: url("/public/images/global/WoodenSwordCursor.png"), auto;
}

body {
    margin: 0;
    overflow-y: scroll;

}



.pageTitle {
    position: relative;
    width: 100%;
    height: 300px;
    
}
.pageTitle * {
    margin: 0;
}
div .pageTitleSpace {
    height: 50px;
    background-color: rgb(0, 96, 151);
}
div .pageTitleContent {
    height: 250px;
    background-color: rgb(2, 109, 197);
}
div .pageTitle .pageTitleText {
    height: 150px;
    font-size: clamp(10px, 5vw, 50px);
    white-space: nowrap;
    font-family: 'DotGothic16', sans-serif;
    text-align: center;
    color: white;
    position: relative;
    top: 5rem;
}
.pageTitle .pageTitleText span {
    position: relative;
}