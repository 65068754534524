div .profileSection {
    width: 100%;
    height: 100%;
}

.profiles {
    width: 100%;
    text-align: center;
}

.profiles div {
    display: inline-block;
}