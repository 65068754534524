@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap'); /* DotGothic16 | ユーザー名 */
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap'); /* Mochiy Pop One | ラベル */
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap'); /* Yusei Magic | 概要 */

@media screen and (min-width: 401px) {
    .profileCard,
    .profileCard .label,
    .profileCard .snsSites,
    .profileCard .userIcon,
    .profileCard .iconProtect,
    .profileCard .userName,
    .profileCard .msg,
    .profileCard .description {
        width: clamp(300px, 40vw, 400px);
    }

    .profileCard {
        margin: 50px 50px;
    }
}

@media screen and (max-width: 400px) {
    .profileCard,
    .profileCard .label,
    .profileCard .snsSites,
    .profileCard .userIcon,
    .profileCard .iconProtect,
    .profileCard .userName,
    .profileCard .msg,
    .profileCard .description {
        max-width: 350px;
        min-width: 300px;
    }
}

.profileCard {
    height: 550px;
    text-align: center;
    margin-top: 60px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
    border: 1px solid rgb(207, 207, 207);
}

.profileCard .label {
    position: absolute;
    text-align: left;
    top: 0px;
    left: 0px;
}
.profileCard .label div {
    position: relative;
    left: -20px;
}
.profileCard .labelImg {
    position: absolute;
    z-index: 1;
    width: 80px;
    top: -30px;
}
.profileCard .labelText {
    position: absolute;
    top: -30px;
    z-index: 2;
    color: rgb(145, 94, 0);
    font-family: 'DotGothic16', sans-serif;
    font-weight: bold;
    font-size: 50px;
}
.profileCard .labelText span {
    position: relative;
    left: 13px;
    bottom: 1px;
}

.profileCard .imageColor {
    display: block;
    margin-top: -16px;
    border-radius: 10px 10px 0px 0px;
    height: 200px;
    background-color: rgb(144, 214, 40);
}

.profileCard .userIcon {
    position: absolute;
    top: 160px;
    left: 0px;
    z-index: 1;
    margin-top: 80px;
    text-align: center;
}

.profileCard .userIcon img {
    width: calc(100px, 50%, 200px);
    height: 128px;
    margin-top: -180px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    z-index: 1;
}
.profileCard .iconProtect {
    position: absolute;
    top: -112px;
    left: 0px;
    height: 128px;
    z-index: 2;
    border-radius: 50%;
    object-fit: cover;
}
.profileCard .iconProtect img {
    position: relative;
    top: 112px;
    text-align: center;
    height: 130px;
    width: 130px;
}

.profileCard .userName {
    font-family: 'DotGothic16', sans-serif;
    font-weight: bold;
    font-size: 28px;

    position: absolute;
    top: 290px;
}

.profileCard .msg {
    position: absolute;
    z-index: 2;
    top: 235px;
    left: 0px;
    overflow: hidden;
}
.profileCard .msg div {
    width: 140px;
    margin: 0 auto;
    padding: 10px 0px 10px 0px;
    border-radius: 10px;
    background-color: rgb(142, 210, 255);
    font-size: 18px;
    white-space: nowrap;
}
.profileCard .msg p {
    margin: 0;
    font-family: 'Mochiy Pop One', sans-serif;
    color: rgb(0, 56, 119);
}

.profileCard .description {
    font-family: 'Yusei Magic', sans-serif;
    font-size: 20px;

    position: absolute;
    top: 340px;
}

.profileCard .snsSites {
    position: absolute;
    bottom: 0px;
    padding: 20px 0px 20px 0px;
    left: 0px;
    text-align: center;
    background-color: rgb(241, 241, 241);
    border-radius: 0px 0px 10px 10px;
}

.profileCard .snsSites .snsInfo {
    display: inline-block;
    margin: 0px 1rem -10px 1rem;
}
.profileCard .snsSites .snsInfo img {
    width: 35px;
}